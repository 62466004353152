import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Header, Layout } from '../components';
import config from '../../config/website';
import { Container, Centered } from '../styles/shared';


const StyledImg = styled(Img)`
  // width: 800px;
  // height: 400px;
  width: 100%;
  height: 100%;
`;

const Text = styled.div`
  color: #222;
  text-align: center;
  margin: 0.5rem 0 4rem 0;
`;

const Title = styled.h3`
  margin: 0;
`;

const CenteredVideo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 68%;
  padding: 1rem 0;
  // margin-top: 3rem;
`;

const Narrative = ({ data: { markdownRemark: postNode } }) => {
  const {
    title,
    link,
    year,
    director,
    editor,
    writer,
    starring,
    thumbnail,
  } = postNode.frontmatter;
  let wd = writer === director ? writer : null;
  let wde = writer === director && director === editor ? writer : null;

  const WDE = () => {
    return (
      <>
        {wde ? (
          <div>Writer / Director / Editor: {wde}</div>
        ) : (
          <>
            <div>Writer / Director: {wd}</div>
            <div>Editor: {editor}</div>
          </>
        )}
      </>
    );
  };

  return (
    <Layout>
      <Helmet title={`${title} | ${config.siteTitle}`} />
      {/* <Header title={title} /> */}
        {/* <BackButton /> */}
      <CenteredVideo>
        {link ? (
          <>
            <div
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <iframe
                id="demo-reel"
                src={`https://player.vimeo.com/video/${link.substring(
                  link.lastIndexOf('/') + 1
                )}`}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  marginBottom: 0,
                }}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js" />
          </>
        ) : (
          <StyledImg fluid={thumbnail.childImageSharp.fluid} />
        )}
      </CenteredVideo>
      <Text>
        <Title>{title}</Title>
        <div>{year}</div>
        <WDE />
        <div>
          Starring: {starring.map((star, idx) => (idx > 0 ? ', ' : '') + star)}
        </div>
      </Text>
    </Layout>
  );
};

export default Narrative;

Narrative.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query NarrativeBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        year
        length
        link
        writer
        director
        editor
        starring
        winner
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90, traceSVG: { color: "#2B2B2F" }) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      fields {
        slug
        sourceInstanceName
      }
    }
  }
`;
